<template>
  <div class="content">
    <div class="input-container" v-for="(item, index) in inputList" :key="index">
      <input
        ref="input_item"
        type="tel"
        maxlength="1"
        @focus="inputFocus"
        @keyup="inputKeyUp($event, index)"
        @input="inputChange(index)"
        v-model="item.code"
      />
      <div class="devider"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "input-view",
  props: ["messageCode"],
  components: {},
  data() {
    return {
      inputList: [
        {
          code: null
        },
        {
          code: null
        },
        {
          code: null
        },
        {
          code: null
        }
      ]
    };
  },
  created() {},
  mounted() {
    this.$refs.input_item[0].focus();
  },
  methods: {
    inputChange(index) {
      const code = this.inputList[index].code;
      if (code !== null && /\d/.test(code) && index < 3) {
        this.$refs.input_item[index + 1].focus();
      } else if (code !== null && !/\d/.test(code)) {
        this.inputList[index].code = null;
      }
      this.$emit(
        "update:messageCode",
        this.inputList.map(x => x.code).join("")
      );
      this.$emit("comfirm", index);
    },
    inputFocus() {
      let index = 0;
      this.inputList.some((item, i) => {
        index = i;
        return item.code == null;
      });

      this.$refs.input_item[index].focus();
    },
    inputKeyUp($event, index) {
      // console.log($event.code, $event.keyCode)
      // console.log('现在在', index);
      if ($event.keyCode == 8 && index > 0) {
        index--;
        this.inputList[index].code = null;
        this.$refs.input_item[index].focus();
      }
    }
  }
};
</script>

<style scoped lang='scss'>
.content {
  display: flex;
}
.input-container {
  margin-right: 20px;
  input {
    text-align: center;
    margin-top: 2px;
    width: 50px;
    height: 40px;
    font-size: 24px;
    font-weight: 400;
    color: #020202;
    line-height: 33px;
    box-sizing: border-box;

    &:focus + div.devider {
      background-color: #3D7FFF;
    }
  }
  .devider {
    width: 50px;
    height: 1px;
    background: #e6e6e6;

    &.has-code {
      background-color: #3D7FFF;
    }
  }
  .devider-active {
    width: 50px;
    height: 1px;
    background: #333333;
  }
}
</style>
