import Vue from 'vue'
import App from './App.vue'
import router from './router';
import $axios from './axios';
import {initPlugin} from '@/plugin';
import GlobalComponentInit from './global-component-init';
import store from './store/index';
Vue.config.productionTip = false;
Vue.prototype.$axios = $axios;

//动态加载open-data SDK
// import "./utils/wx"

// Vue.use(DatetimePicker);
initPlugin(Vue);
GlobalComponentInit();
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
