import axios from 'axios';
import {environment} from '@/environment';
import {Toast} from 'vant';

let instance;
function formatterParams(obj) {
  return Object.keys(obj).map((key) => `${key}=${obj[key]}`).join('&');
}

class RefreshToken {
  constructor() {
    this.isRefreshing = false;
    this.refreshPromise = null;
  }
  static get getInstance() {
    if (!instance) {
      instance = new RefreshToken();
    }
    return instance;
  }

  refresh(refresh_token) {
    const rt = this;
    if (!this.refreshPromise) {
      this.refreshPromise = new Promise((resolve, reject) => {
        rt.isRefreshing = true;
        let p = {
          grant_type:'refresh_token',
          refresh_token:refresh_token,
        }
        axios.post(environment.gzApi + '/account/token', formatterParams(p), {
          headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(r => {
          if (r.data.access_token) {
            const token = {
              ...r.data,
              expires_time: new Date().getTime() + r.data.expires_in * 1000
            };
            localStorage.setItem('token', JSON.stringify(token));
            rt.isRefreshing = false;
            rt.refreshPromise = null;
            resolve(token);
          } else {
            localStorage.removeItem('token');
            reject(r.data);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          reject(err);
        })
      });
    }
    return this.refreshPromise;
  }

 
  addConfig(config, refreshTokenBack) {
    return new Promise(resolve => {
      refreshTokenBack.then(token => {
        config.headers.Authorization = 'Bearer ' + token.access_token;
        resolve(config);
      })
    })
  }
}

export default RefreshToken.getInstance;
