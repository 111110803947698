import axios from 'axios';
import auth from '../auth/config'
import router from '@/router';
import axiosRetry from 'axios-retry';
import {environment} from '@/environment';

const gzApiInstance = axios.create({
  baseURL: environment.gzApi,
  timeout: 10000
});


axiosRetry(gzApiInstance, {retry: 3});

gzApiInstance.interceptors.request.use(auth);

gzApiInstance.interceptors.request.use((config) => {
  // console.log('请求config：', config)
  return config
})

gzApiInstance.interceptors.response.use(res => {
  // console.log('接口返回数据：', res)
  return res;
}, e => {
  console.log('接口响应：', e)
  if (e.response && e.response.status === 401) {
    router.push({
      path: "/login",
    }).then();
  }
  return e.response;
});



export default gzApiInstance;
