// import axios from 'axios';
import refreshInstance from './refresh-token'

function notInterceptorUrl(url) {
  const notInterceptorList = [];
  notInterceptorList.push('account/token');
  return notInterceptorList.some(list => url.includes(list))
}

function auth(config) {
  config.withCredentials = true;
  // var a = location.host.includes('localhost:8080');
  // if (a) {
  //   config.headers.Authorization = 'Bearer nJnlqJCdNptulbEDX8l37k8qtBRiB4cLzmKSM7tyhIqRm830OPjkZ9BZovWIU7AKMc4X5zCrMOQSUe29S4BI680dQOOVL3-KAlMNIlWwLqmF0p_0gBEXlGClxGLreSMcehXeE1GrJ77TEBQMx4zaeYpO9zzf2zQBUiE4y80MeAlG1myvu0vQy3YIMlEQdCZuWbvqulH7F3qVpLDVW_ynd1lwPfmq6L99r3MzVb6PZw0';

  //   return config;
  // }
  if (notInterceptorUrl(config.url)) {
    return config;
  }

  let token = JSON.parse(localStorage.getItem('token'));
  if (!token) {
    return config;
  }

  if (refreshInstance.isRefreshing) {
    return refreshInstance.addConfig(config, refreshInstance.refresh(token.refresh_token))
  }

  if (!token.expires_time || token.expires_time < new Date().getTime() + 1000) {
    return refreshInstance.addConfig(config, refreshInstance.refresh(token.refresh_token))
  }
  config.headers.Authorization = 'Bearer ' + token.access_token;
  
  return config;
}

export default auth;
