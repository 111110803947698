import Button from 'vant/lib/button';
import 'vant/lib/button/style';
import {DatetimePicker, Picker, Popup, Cell, Switch, Loading, Toast, Dialog} from 'vant';
import 'vant/lib/index.css';

export function vantUiPlugin(Vue) {
    Vue.use(Button);
    Vue.use(DatetimePicker);
    Vue.use(Popup);
    Vue.use(Cell);
    Vue.use(Picker);
    Vue.use(Switch);
    Vue.use(Loading);
    Vue.use(Toast);
    Vue.use(Dialog)
}
