
//是否微信平台
export function isWx() {
  const ua = navigator.userAgent.toLowerCase();
  return /MicroMessenger/i.test(ua);
}

// 是否企业微信平台
export function isQyWx() {
  const ua = navigator.userAgent.toLowerCase();
  return isWx() && /wxwork/gi.test(ua);
}

//是否微信，非企业微信
export function isOnlyWx() {
  return isWx() && !isQyWx();
}

//是否本地
export function isLocal() {
  return location.origin === 'http://localhost:8080'
}

//动态引入脚本
export function dynamicImportScript(src, referrerpolicy) {
  let script = document.createElement('script');
  script.src = src;
  script.referrerpolicy = referrerpolicy;
  document.body.appendChild(script)
}

//判断是否移动端
export function isMobile() {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) === null ? false : true;
}

//清空localStorage操作
export function clearLocalStarage() {
  let clipboardFlag = localStorage.getItem('alreadyShowClipboard');
  let repeatCompany = localStorage.getItem('alreadyShowRepeativeFlag');
  localStorage.clear();
  //还原剪切标志位
  clipboardFlag === 'true' && localStorage.setItem('alreadyShowClipboard', "true");
  repeatCompany === "true" && localStorage.setItem('alreadyShowRepeativeFlag', "true");
}

//复制指定元素内的文本
export function copyToClipboard(id, cb) {
  let copyDom = document.querySelector(`#${id}`);
  //创建选中范围
  var range = document.createRange();
  range.selectNode(copyDom);
  //移除剪切板中内容
  window.getSelection().removeAllRanges();
  //添加新的内容到剪切板
  window.getSelection().addRange(range);
  //复制
  document.execCommand('copy');
  //执行回调
  cb();
}