<template>
  <div class="content">
    <div class="input-container">
      <input :value="messageCode" @input="changeVal" type="tel" maxlength="6" />
      <div class="devider"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "input-view-verifiction",
  props: ["messageCode"],
  methods: {
    changeVal(e) {
      const v = e.target.value;
      this.$emit('update:messageCode', v);
      if (!v) {
        return;
      }
      if (v.length === 6) {
        this.$emit('comfirm');
      }
    }
  }
};
</script>

<style scoped lang='scss'>
.content {
  display: flex;
  justify-content: space-between;
  width: 100%;

}
input{
}
.input-container {
  width: 100%;

  input {
    text-align: left;
    margin-top: 2px;
    width: 100%;
    height: 40px;
    font-size: 24px;
    font-weight: 400;
    color: #020202;
    line-height: 33px;
    box-sizing: border-box;

    &:focus + div.devider {
      background-color: #3D7FFF;
    }
  }

  .devider {
    width: 100%;
    height: 1px;
    background: #e6e6e6;

    &.has-code {
      background-color: #3D7FFF;
    }
  }

  .devider-active {
    width: 40px;
    height: 1px;
    background: #333333;
  }
}
</style>
