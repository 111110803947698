import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    comanyId: '',
    istest: false,
    isWx: false,
    isQyWx: false,
    onlyWx: false,
    bindingMobile: "",
    userid: "",
    departmentid: "",
    isManager: false,
    wxAccountLinked: false,//微信号与手机号是否建立关联
  }),
  getters: {
    store_comanyId(state) {
      if(!state.comanyId){
        return localStorage.getItem("companyId");
      }
      return state.comanyId;
    },
   
    store_istest(state){
      return state.istest;
    }
  },
  mutations: {
    $setCompanyId(state, values) {
      state.comanyId = values;
    },
    changeWxStatus(state, v) {
      state.isWx = v;
    },
    changeQyWxStatus(state, v) {
      state.isQyWx = v;
    },
    changeOnlyWxStatus(state, v) {
      state.onlyWx = v;
    },
   
    $setIsTest(state,values){
      state.istest = values;
    },
    //修改绑定手机号（企业微信）
    $changeBindingMobile(state, values){
      state.bindingMobile = values;
    },
    //更新userid的值
    $changeUserId(state,values){
      state.userid = values
    },
    //更新departmentid的值
    $changeDepartmentId(state, values){
      state.departmentid = values;
    },
    //当前用户是否是管理员
    $changeIsManager(state, values){
      state.isManager = values;
    },
    //更新手机与微信的绑定关系
    $changeWxAccountLinked(state, values){
      state.wxAccountLinked = values;
    }
  },
  actions: {
    store_SetCompanyId(context, values) {
      // sessionStorage.setItem('sxdfsfdsadefs', 1);
      localStorage.setItem("companyId", values);
      context.commit('$setCompanyId', values);
    },
    store_setIsTest(context, values){
      context.commit('$setIsTest', values);
    }
  }
})
