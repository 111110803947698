<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// vconsole使用案例
import VConsole from "vconsole";
import { isLocal, isWx, isQyWx, clearLocalStarage } from "./utils/utils";
import { environment } from "./environment/index";
export default {
  name: 'App',
  components: {},
  data() {
    return {
      isceshi: true,
      vconsole: null,
    };
  },
  created() {
    this.isceshi = location.host.includes("localhost:8080") ||
        location.host.includes("test");
    this.$store.dispatch("store_setIsTest", this.isceshi);
    this.$options.judgeWxEvn.call(this);
    //初始化绑定手机号
    let mobile = localStorage.getItem("mobile");
    if (mobile) {
      this.$store.commit("$changeBindingMobile", mobile);
    }
    //将userid保存到store中
    if (isQyWx()) {
      let userid = localStorage.getItem("userid");
      if (userid) {
        this.$store.commit("$changeUserId", userid);
      }
    }
    if(isQyWx() && !sessionStorage.getItem('refresh')){
      clearLocalStarage();
      //设置refresh标志
      sessionStorage.setItem('refresh', 'true');
    }
    //从sessionStorage中，获取当前是否是管理员
    if(sessionStorage.getItem('isManager')==='true'){
      this.$store.commit('$changeIsManager', true)
    }
    //开始调试工具
    this.openVConsole();
  },
  //判断平台
  judgeWxEvn() {
    this.$store.commit("changeWxStatus", isWx());
    this.$store.commit("changeQyWxStatus", isQyWx());
    this.$store.commit("changeOnlyWxStatus", isWx() && !isQyWx());
  },
  methods: {
    //打开调试工具
    openVConsole() {
      if (this.isceshi && !isLocal()) {
        //创建vconsole实例
        this.vconsole = new VConsole({ theme: "dark" });
      }
    },
  },
  destroyed() {
    //如果创建了vConsole实例，则退出时清除
    this.vconsole && this.vconsole.destroyed();
  },
};
</script>

<style lang="scss">
@import "styles";
@import "styles/common.scss";
// .van-overlay {
//   background-color: #191a28;
//   opacity: 0.4 !important;

// }
.van-overlay {
  background-color: rgba(25, 26, 40, 0.4);
  //  opacity: 0.4  !important;
}
</style>
