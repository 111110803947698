import VueRouter from 'vue-router';
import Vue from 'vue';

Vue.use(VueRouter);

VueRouter.prototype.push = (function (push) {
  return function (options) {
    return push.call(this, options).catch(err => err)
  }
})(VueRouter.prototype.push);

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/homepage',
    component: () => import('../components/homepage/homepage-components.vue'),
    children: [
      { path: '', redirect: 'salarylist' },
      { path: 'salarylist', component: () => import('../components/homepage/salary-list.vue') },
      { path: 'mine', component: () => import('../components/mine/mine.vue') }
    ]
  },
  { path: '/salarydetail', component: () => import('../components/homepage/salary-detail.vue') },
  { path: '/salarydetailshortmsg', component: () => import('../components/homepage/salary-detail-shortmsg.vue') },
  { path: '/login', component: () => import('../components/login/login-components.vue') },
  // { path: '/logintest', component: () => import('../components/login/login-test.vue') },
  { path: '/verification', component: () => import('../components/login/verification-code.vue') },
  { path: '/setsafecode', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/set-safecode.vue') },
  { path: '/reconfirmsafecode', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/again-confirm-safecode.vue') },
  { path: '/entersafecode', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/enter-safecode.vue') },
  { path: '/resetsafecode', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/reset-safecode.vue') },
  { path: '/verificateinfo', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/verificate-info.vue') },
  { path: '/verificateshortmgs', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/verificate-info-shortmes.vue') },
  { path: '/relatedemail', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/related-email.vue') },
  { path: '/companylist', component: () => import(/* webpackChunkName: "small-module" */ '../components/mine/company-list.vue') },
  { path: '/reback', component: () => import('../components/homepage/salary-reback.vue') },
  { path: '/msgQrcode', component: () => import('../components/homepage/msg-qrcode.vue') },
  { path: '/rebackshortmsg', component: () => import(/* webpackChunkName: "small-module" */ '../components/homepage/salary-reback-shortmsg.vue') },
  { path: '/i/:name', component: () => import('../components/shortmessage.vue') },
  { path: '/validatephone', component: () => import('../components/mine/validate-phone.vue') },
];

export default new VueRouter({
  routes,
  mode: 'history'
});
