import Vue from 'vue';

// 全局组件
import inputView from './common/input-view.vue';
import inputViewVerifiction from './common/input-view-verifiction.vue';

export default function () {
  Vue.component('input-view', inputView);
  Vue.component('input-view-verifiction', inputViewVerifiction);
}
